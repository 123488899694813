.Post {
    display: flex;
    flex-direction: row;
    border: 1px solid blue;
    max-height: 20vh;
}

.postImage{
    width: 30%;
}

.postPreview {
    width: 70%;
    overflow-y: scroll;
}

.Post >div {
    border: 1px solid blue;
}