#headder {
  display: flex;
  flex-direction: column;
  height: 25vh;
  border: 1px red solid;
  overflow: hidden;
}

#logo {
  display: flex;
  height: 15vh;
  border: 1px solid red;
  overflow: hidden;
}

#menu {
  display: flex;
  flex-direction: row;
  height: 10vh;
  border: 1px solid red;
  overflow: hidden;
  
}

#main {
  display: flex;
  flex-direction: column;
  height: 70vh;
  border: 1px red solid;
  overflow: scroll;
}

#footer {
  display: flex;
  flex-direction: column;
  height: 5vh;
  border: 1px red solid;
  overflow: hidden;
}